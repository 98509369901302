import { Link, RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import React from 'react';
import icon from '../assets/DuePay_white.png';
import { useProfile } from '../services/profile';
import { logout } from '../rest-client';

const HEIGHT = 48;

const links = [
    { path: '/', name: 'Dashboard' },
    { path: '/invoices', name: 'Invoices' },
    { path: '/payments', name: 'Payments' },
    { path: '/customers', name: 'Customers' },
    { path: '/settings', name: 'Settings' },
];

const ARROW_SIZE = 8;

export default function MainLayout({
    children,
    ...props
}: RouteComponentProps & { children: React.ReactNode }) {
    const profile = useProfile();

    return (
        <div className="w-screen overflow-hidden">
            <header
                className="h-12 fixed top-0 left-0 right-0 bg-blue-400"
                style={{ height: HEIGHT }}
            >
                <nav className="h-full flex items-center">
                    <img src={icon} alt="logo" className="h-6 mx-6" />
                    <ol className="flex h-full">
                        {links.map((link) => {
                            const active = props.location?.pathname === link.path;
                            return (
                                <li key={link.path} className="h-full relative flex justify-center">
                                    <Link
                                        to={link.path}
                                        className={clsx(
                                            'px-4 h-full block flex items-center text-white',
                                            { 'font-bold': active }
                                        )}
                                    >
                                        {link.name}
                                    </Link>
                                    {active && (
                                        <div
                                            className="absolute bg-white"
                                            style={{
                                                height: ARROW_SIZE,
                                                width: ARROW_SIZE,
                                                transform: 'rotate(45deg)',
                                                bottom: -ARROW_SIZE / 2,
                                            }}
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </ol>
                    {profile.data && (
                        <button
                            className="ml-auto text-white text-right mr-6"
                            type="button"
                            onClick={() => logout()}
                        >
                            <div className="text-xs -mb-1 text-grey-200">{`${profile.data.user.firstName} ${profile.data.user.lastName}`}</div>
                            <div>{profile.data.account?.name}</div>
                        </button>
                    )}
                </nav>
            </header>
            <main style={{ paddingTop: HEIGHT, minHeight: '100vh' }} className="bg-background">
                <div className="max-w-screen-xl mx-auto">{children}</div>
            </main>
        </div>
    );
}
