import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    useXeroExpenseAccounts,
    useXeroBankAccounts,
    useXeroAccountsConfigure,
} from '../../services/xero';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { useProfile } from '../../services/profile';

function Accounts({ navigate }: RouteComponentProps) {
    const xeroExpenseAccounts = useXeroExpenseAccounts();
    const xeroBankAccounts = useXeroBankAccounts();
    const profile = useProfile();
    const [bankAccountId, setBankAccountId] = useState('');
    const [expenseAccountId, setExpenseAccountId] = useState('');
    const xeroAccountsConfigure = useXeroAccountsConfigure(bankAccountId, expenseAccountId);

    /**
     * After loading accounts, make sure some account is selected.
     */
    useEffect(() => {
        if (!bankAccountId && xeroBankAccounts.data) {
            setBankAccountId(xeroBankAccounts.data[0].id);
        }
        if (!expenseAccountId && xeroExpenseAccounts.data) {
            setExpenseAccountId(xeroExpenseAccounts.data[0].id);
        }
    }, [bankAccountId, expenseAccountId, xeroBankAccounts.data, xeroExpenseAccounts.data]);

    useEffect(() => {
        if (!xeroBankAccounts.executed) {
            xeroBankAccounts.refetch();
        }
        if (!xeroExpenseAccounts.executed) {
            xeroExpenseAccounts.refetch();
        }
    }, [xeroBankAccounts, xeroExpenseAccounts]);

    /**
     * After setting tenant > go to accounts.
     */
    useEffect(() => {
        if (xeroAccountsConfigure.success && navigate) {
            profile.reset();
            navigate('/');
        }
    }, [navigate, profile, xeroAccountsConfigure.success]);

    if (!xeroBankAccounts.data || !xeroExpenseAccounts.data) {
        return null;
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-center mb-4">Select your Xero Bank Accounts</h1>
            <div className="text-center">
                <Select
                    label="Bank account"
                    options={xeroBankAccounts.data.map((d) => ({
                        value: d.id,
                        name: d.name,
                    }))}
                />
                <Select
                    label="Expense account"
                    options={xeroExpenseAccounts.data.map((d) => ({
                        value: d.id,
                        name: d.name,
                    }))}
                    mt-4
                />
                <Button onClick={() => xeroAccountsConfigure.post()} mt-4>
                    Submit
                </Button>
            </div>
        </>
    );
}

export default Accounts;
