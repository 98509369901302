import { services } from '../rest-client';

type LoginRequest = {
    username: string;
    password: string;
};
type LoginResponse = {
    success: boolean;
    twoFactorRequired: boolean;
    twoFactorRegistrationRequired: boolean;
    message: string | null;
    token: string;
    tokenExpires: string;
};

export function useLogin() {
    return services.auth.usePost<LoginRequest, LoginResponse>('/authentication/login', {
        parseRequest: (request) => ({
            ...request,
            clientCode: 'MERCHANT',
        }),
    });
}
