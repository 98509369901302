import { createSelector } from 'reselect';
import * as R from 'ramda';
import { createClient } from './__internals/rest-local-sync';
import { ApiState, RootState } from './__internals/store/reducer';

const tokenSelector = createSelector(
    (state: RootState) => state.auth,
    (api: ApiState) => R.path<string>(['authentication/login', 'post', 'data', 'token'], api)
);

function setAuthToken(state: RootState) {
    const token = tokenSelector(state);
    return token;
}

const client = createClient({
    apis: {
        merchant: {
            baseURL: 'https://merchant.api.dev.duepay.co.za',
            setAuthToken,
        },
        auth: {
            baseURL: 'https://auth.api.dev.duepay.co.za',
            setAuthToken,
        },
    },
    localStorageKey: 'duepay/merchant',
});

const { services, logout } = client;

export { services, logout };

export default client;
