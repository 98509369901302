import { RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import React from 'react';
import Button from '../components/Button';
import { useBills } from '../services/bills';
import { useXeroSync } from '../services/xero';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Invoices(props: RouteComponentProps) {
    const xeroSync = useXeroSync();
    const bills = useBills();

    return (
        <>
            <div className="bg-white p-6 mt-6 shadow">
                <div className="flex items-center space-x-4">
                    <Button small onClick={() => xeroSync.post()} loading={xeroSync.loading}>
                        Sync Xero
                    </Button>
                    <Button small onClick={() => bills.refetch()} loading={bills.loading}>
                        Get bills
                    </Button>
                </div>
                {bills && bills.data && (
                    <table className="w-full mt-6 text-grey-400">
                        <thead className="uppercase text-left bg-grey-100 text-sm">
                            <tr>
                                <th className="py-2 px-2">Due Date</th>
                                <th className="py-2 px-2">Status</th>
                                <th className="py-2 px-2">Customer</th>
                                <th className="py-2 px-2 text-right">Amount</th>
                                <th className="py-2 px-2 text-right">Remaining balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bills.data.items.map((bill) => (
                                <tr key={bill.id}>
                                    <td className="py-2 px-2 border-b border-grey-100">
                                        {format(new Date(bill.dueAt), 'd MMM yyyy')}
                                    </td>
                                    <td className="py-2 px-2 border-b border-grey-100">
                                        {bill.statusCode}
                                    </td>
                                    <td className="py-2 px-2 border-b border-grey-100">
                                        {bill.customerName}
                                    </td>
                                    <td className="py-2 px-2 border-b border-grey-100 text-right">
                                        {bill.amountDue}
                                    </td>
                                    <td className="py-2 px-2 border-b border-grey-100 text-right">
                                        {bill.amountDue - bill.amountPaid}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
}
