import clsx from 'clsx';
import * as React from 'react';

export default function Select({
    style,
    value,
    defaultValue,
    onChange,
    options,
    label,
    name,
    inputRef,
    disabled,
    error,
    ...props
}: {
    style?: React.CSSProperties;
    value?: string;
    defaultValue?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    label?: string;
    options: { value: string; name: string }[];
    name?: string;
    inputRef?: (instance: HTMLSelectElement | null) => void;
    postIcon?: React.ReactNode;
    disabled?: boolean;
    error?: string | undefined;
    [rem: string]: unknown;
}): JSX.Element {
    return (
        <label style={style} className={clsx(props, 'block relative text-left')}>
            {label && <span className="text-grey-400 block text-sm mb-1">{label}</span>}
            <select
                className={clsx(
                    'border rounded p-2 focus:outline-none w-full transition duration-100',
                    { 'border-red-400': error, 'border-grey-200 focus:border-blue-400': !error }
                )}
                value={value}
                onChange={onChange}
                name={name}
                ref={inputRef}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
            {error && <div className="text-red-400 text-xs">{error}</div>}
        </label>
    );
}
