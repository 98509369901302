import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from '@reach/router';
import qs from 'qs';
import Input from '../components/Input';
import Button from '../components/Button';
import logo from '../assets/DuePay.png';
import { useLogin } from '../services/auth';

type FormData = {
    username: string;
    password: string;
};

function Login(props: RouteComponentProps) {
    const { handleSubmit, register, errors } = useForm<FormData>();
    // eslint-disable-next-line react/destructuring-assignment
    const redirect = qs.parse(props.location?.search.slice(1) as string).redirect as string;

    const login = useLogin();

    function onSubmit(values: FormData) {
        login.post(values);
    }

    useEffect(() => {
        if (login.data?.success && props.navigate) {
            console.log({ redirect });
            props.navigate(redirect || '/');
        }
    }, [login.data, props, redirect]);

    return (
        <div className="flex justify-center bg-background min-h-screen items-center relative">
            <img src={logo} alt="logo" className="w-48 absolute top-0 left-0 m-6" />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-w-md w-full bg-white shadow md:p-8 px-2 py-4 flex flex-col"
            >
                <h1 className="text-2xl font-bold">Welcome back</h1>
                <Input
                    label="Email"
                    mt-4
                    type="email"
                    inputRef={register({ required: true, minLength: 3 })}
                    name="username"
                    error={errors.username && 'Must be at least 3 characters long'}
                />
                <Input
                    label="Password"
                    mt-4
                    type="password"
                    inputRef={register({ required: true, minLength: 2 })}
                    name="password"
                    error={errors.password && 'Must be at least 2 characters long'}
                />
                {login.error && (
                    <div className="bg-red-200 p-2 rounded mt-4 text-grey-400">{login.error}</div>
                )}
                <Button type="submit" mx-auto mt-8 w-40 loading={login.loading}>
                    Login
                </Button>
            </form>
        </div>
    );
}

export default Login;
