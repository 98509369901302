import axios from 'axios';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import getGetGenerator from './use-get';
import getPostGenerator from './use-post';
import { ApiState, RootState } from '../store/reducer';

export default function createApi(baseURL: string, name: string) {
    const api = axios.create({
        baseURL,
    });

    return {
        usePost: getPostGenerator(api, name),
        useGet: getGetGenerator(api, name),
        useSelector: (selector: (state: ApiState) => unknown) =>
            useSelector(createSelector((state: RootState) => state[name], selector)),
        api,
    };
}
