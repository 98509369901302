import clsx from 'clsx';
import * as React from 'react';

export default function Input({
    style,
    type,
    value,
    defaultValue,
    onChange,
    label,
    name,
    inputRef,
    placeholder,
    disabled,
    error,
    ...props
}: {
    style?: React.CSSProperties;
    type?: 'email' | 'password' | 'text';
    value?: string | number;
    defaultValue?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    name?: string;
    inputRef?: (instance: HTMLInputElement | null) => void;
    placeholder?: string;
    postIcon?: React.ReactNode;
    disabled?: boolean;
    error?: string | undefined;
    [rem: string]: unknown;
}): JSX.Element {
    return (
        <div style={style} className={clsx(props)}>
            <label className="block relative">
                {label && <span className="text-grey-400 block text-sm mb-1">{label}</span>}
                <input
                    placeholder={placeholder}
                    className={clsx(
                        'border rounded p-2 focus:outline-none w-full transition duration-100',
                        { 'border-red-400': error, 'border-grey-200 focus:border-blue-400': !error }
                    )}
                    value={value}
                    type={type}
                    onChange={onChange}
                    name={name}
                    ref={inputRef}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
                {error && <div className="text-red-400 text-xs">{error}</div>}
            </label>
        </div>
    );
}
