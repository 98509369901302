import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Button from '../components/Button';
import logo from '../assets/DuePay.png';
import { useXeroLink } from '../services/xero';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RegisterXero(props: RouteComponentProps) {
    console.log({ props });
    const xeroLink = useXeroLink();

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        window.location.href = xeroLink.data?.redirectUrl as string;
    }

    if (xeroLink.loading) {
        // loading
        return null;
    }

    return (
        <div className="flex justify-center bg-background min-h-screen items-center relative">
            <img src={logo} alt="logo" className="w-48 absolute top-0 left-0 m-6" />
            <form
                onSubmit={onSubmit}
                className="max-w-md w-full bg-white shadow md:p-8 px-2 py-4 flex flex-col"
            >
                <h1 className="text-2xl font-bold text-center mb-4">
                    Allow DuePay to connect to your Xero Account
                </h1>
                <p className="text-center">
                    By allowing DuePay to access your account, you’ll ensure that payments can be
                    requested and reconciled through Xero.
                </p>
                <Button type="submit" mx-auto mt-8 w-40 loading={xeroLink.loading}>
                    Connect to Xero
                </Button>
            </form>
        </div>
    );
}

export default RegisterXero;
