import React from 'react';
import { Router } from '@reach/router';

import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import RegisterXero from './pages/RegisterXero';
import Xero from './pages/Xero';
import Settings from './pages/Settings';
import Invoices from './pages/Invoices';
import Payments from './pages/Payments';
import Customers from './pages/Customers';

import client from './rest-client';
import MainLayout from './layouts/MainLayout';

export default function App() {
    return (
        <client.Provider>
            <Router>
                <Login path="/login" />
                <Register path="/register" />
                <PrivateRoute path="/xero" ignoreXero>
                    <Xero path="/" />
                </PrivateRoute>

                <PrivateRoute path="/register-xero" ignoreXero>
                    <RegisterXero path="/" />
                </PrivateRoute>

                <PrivateRoute path="/" Layout={MainLayout}>
                    <Settings path="/settings" />
                    <Invoices path="/invoices" />
                    <Payments path="/payments" />
                    <Customers path="/customers" />
                    <Dashboard path="/" />
                </PrivateRoute>
            </Router>
        </client.Provider>
    );
}
