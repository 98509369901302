import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { AxiosInstance } from 'axios';
import { normalizePath } from '../../utils';
import { RootState, StoreData } from '../store/reducer';

type Options<Req, Res> = {
    parseRequest?: (request?: Req) => unknown;
    parseResponse?: (response: unknown) => Res;
};

export default function getPostGenerator(api: AxiosInstance, name: string) {
    return function usePost<Req, Res>(u: string, options: Options<Req, Res> = {}) {
        const url = normalizePath(u);
        const props = useSelector((state: RootState) =>
            R.path<StoreData>([name, url, 'post'], state)
        );
        const dispatch = useDispatch();

        const post = useCallback(
            async (payload?: Req) => {
                try {
                    dispatch({ type: `${name}|${url}|post|loading` });
                    const response = await api.post(
                        url,
                        options.parseRequest ? options.parseRequest(payload) : payload
                    );
                    dispatch({
                        type: `${name}|${url}|post|data`,
                        payload: options.parseResponse
                            ? options.parseResponse(response.data)
                            : response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: `${name}|${url}|post|error`,
                        payload: error.toString(),
                    });
                }
            },
            [dispatch, options, url]
        );

        const data = (props?.data as Res) || undefined;

        return {
            data,
            loading: props?.loading,
            error: props?.error,
            executed: props?.executed,
            success: props?.success,
            post,
        };
    };
}
