import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useXeroLinkPost } from '../../services/xero';

function Link({ code, navigate }: RouteComponentProps & { code: string }) {
    const xeroLinkPost = useXeroLinkPost(code);

    /**
     * If xeroLink not yet linked > link.
     */
    useEffect(() => {
        if (code && !xeroLinkPost.executed) {
            xeroLinkPost.post();
        }
    }, [code, xeroLinkPost]);

    /**
     * After linking xero > go to tenants.
     */
    useEffect(() => {
        if (xeroLinkPost.success && navigate) {
            navigate('/xero?step=tenant');
        }
    }, [navigate, xeroLinkPost.success]);

    return (
        <>
            <h1 className="text-2xl font-bold text-center mb-4">Linking your Xero Account...</h1>
            <p className="text-center">Please don’t close your window.</p>
        </>
    );
}

export default Link;
