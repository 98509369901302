import { services } from '../rest-client';

export type BillsResponse = {
    items: {
        id: string;
        merchantId: string;
        merchantAccountId: string;
        statusCode: string;
        amountDue: number;
        amountPaid: number;
        currencyCode: string;
        billedAt: string;
        dueAt: string;
        reference: string;
        billSubTotal: number;
        billDiscount: number;
        billTax: number;
        billTotal: number;
        contactNumber: string;
        contactEmail: string;
        providerCode: string;
        providerIdentifier: string;
        customerId: string;
        customerName: string;
        customerAccountId: string;
        customerAuthorizedAt: string;
        failedCode: string;
        failedReason: string;
        paymentMandateId: string;
        paymentId: string;
        createdTimestamp: number;
        visible: true;
        createdAt: string;
    }[];
    itemsTotal: number;
    itemsPerPage: number;
    pageCurrent: number;
    pagesTotal: number;
};

export function useBills() {
    return services.merchant.useGet<BillsResponse>(`bills`);
}
