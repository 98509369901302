import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useLogin } from './services/auth';
import { useProfile } from './services/profile';

function ProfileGuard(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    {
        children,
        ignoreXero,
        Layout,
        ...props
    }: RouteComponentProps & {
        ignoreXero?: boolean;
        Layout?: (props: RouteComponentProps & { children: React.ReactNode }) => JSX.Element;
        children: React.ReactNode;
    }
) {
    const profile = useProfile();

    const xero = profile?.data?.integrations.find((i) => i.billProviderCode === 'XERO');

    const hasTenant = xero && xero.settings.find((s) => s.key === 'TenantId');
    const hasBankAccount = xero && xero.settings.find((s) => s.key === 'BankAccountId');
    const hasFeeAccount = xero && xero.settings.find((s) => s.key === 'FeeAccountId');
    const hasAccounts = hasBankAccount && hasFeeAccount;

    const valid = ignoreXero || hasAccounts;

    useEffect(() => {
        if (!profile.data || !props.navigate || ignoreXero) return;

        if (!hasAccounts) {
            props.navigate(`/xero?step=accounts`);
            return;
        }
        if (!hasTenant) {
            props.navigate(`/xero?step=tenant`);
        }
    }, [hasAccounts, hasTenant, ignoreXero, profile.data, props]);

    if (!profile.data) {
        return null;
    }

    if (!valid) {
        // loading
        return null;
    }
    return Layout ? <Layout {...props}>{children}</Layout> : <>{children}</>;
}

function LoginGaurd(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    {
        children,
        ignoreXero,
        Layout,
        ...props
    }: RouteComponentProps & {
        ignoreXero?: boolean;
        Layout?: (props: RouteComponentProps & { children: React.ReactNode }) => JSX.Element;
        children: React.ReactNode;
    }
) {
    const login = useLogin();

    const hasToken = !!login.data?.success;

    useEffect(() => {
        if (!hasToken && props.navigate) {
            props.navigate(
                `/login?redirect=${props?.location?.pathname as string}${
                    (props?.location?.search as string) || ''
                }`
            );
        }
    }, [hasToken, props]);

    if (!hasToken) {
        // loading
        return null;
    }

    return (
        <ProfileGuard ignoreXero={ignoreXero} Layout={Layout} {...props}>
            {children}
        </ProfileGuard>
    );
}

export default LoginGaurd;
