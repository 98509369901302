import { getUnixTime } from 'date-fns';
import * as R from 'ramda';

export type StoreData = {
    data: unknown | undefined;
    /**
     * If api call is in progress.
     */
    loading: boolean;
    /**
     * If error occured.
     */
    error: string | undefined;
    /**
     * Last time call was executed.
     */
    lastUpdated: number | undefined;
    /**
     * If call was ever executed (start of api call)
     */
    executed: boolean;
    /**
     * If successfull.
     */
    success: boolean | undefined;
};

export type ApiState = {
    // name of url
    [url: string]: {
        // name of method
        [method: string]: StoreData;
    };
};

export type RootState = {
    // name of api
    [name: string]: ApiState;
};

const getDefaultState = () => ({
    data: undefined,
    error: undefined,
    loading: false,
    executed: false,
    success: undefined,
    lastUpdated: getUnixTime(new Date()),
});

export default function getRootReducer(localStorageKey: string) {
    const cachedState = localStorage.getItem(localStorageKey);

    const initialState: RootState = cachedState ? (JSON.parse(cachedState) as RootState) : {};

    /**
     * Remove all cached "loading" states.  Maybe should remove all error states as well (forcing
     * refetch on refresh?)
     */
    const parsedInitialState = R.map<RootState, RootState>(
        (api) =>
            R.map(
                (url) =>
                    R.map((method) => {
                        if (method.loading || method.error) {
                            return getDefaultState();
                        }
                        return method;
                    }, url),
                api
            ),
        initialState
    );

    function rootReducer(
        state = parsedInitialState,
        {
            type,
            payload,
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { type: string; payload?: any }
    ): RootState {
        const [name, url, method, action] = type.split('|');

        if (name === 'LOGOUT') {
            return {};
        }

        if (!name || !url) return state;

        if (action === 'data') {
            const props = {
                executed: true,
                data: payload,
                error: undefined,
                loading: false,
                success: true,
                lastUpdated: getUnixTime(new Date()),
            };
            return R.assocPath([name, url, method], props, state);
        }
        if (action === 'loading') {
            const existing = R.path<StoreData>([name, url, method], state);
            const props = {
                data: existing?.data,
                error: undefined,
                loading: true,
                executed: true,
                lastUpdated: getUnixTime(new Date()),
            };
            return R.assocPath([name, url, method], props, state);
        }
        if (action === 'error') {
            const existing = R.path<StoreData>([name, url, method], state);
            const props = {
                executed: true,
                data: existing?.data,
                error: payload || 'An unkown error has occured',
                loading: false,
                success: false,
                lastUpdated: getUnixTime(new Date()),
            };
            return R.assocPath([name, url, method], props, state);
        }
        if (!action) {
            return R.assocPath([name, url, method], getDefaultState(), state);
        }
        return state;
    }

    return rootReducer;
}
