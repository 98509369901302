import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useXeroTenants, useXeroTenantsPost } from '../../services/xero';
import Select from '../../components/Select';
import Button from '../../components/Button';

function Tenant({ navigate }: RouteComponentProps) {
    const xeroTenants = useXeroTenants();
    const [tenantId, setTenantId] = useState('');
    const xeroTenantsPost = useXeroTenantsPost(tenantId);

    /**
     * After loading tenants, make sure some tenant is selected.
     */
    useEffect(() => {
        if (!tenantId && xeroTenants.data) {
            setTenantId(xeroTenants.data[0].id);
        }
    }, [tenantId, xeroTenants.data]);

    /**
     * After setting tenant > go to accounts.
     */
    useEffect(() => {
        if (xeroTenantsPost.success && navigate) {
            navigate('/xero?step=accounts');
        }
    }, [navigate, xeroTenantsPost.success]);

    if (!xeroTenants.data) {
        return null;
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-center mb-4">
                Select your Xero Company Account
            </h1>
            <div className="text-center">
                <Select
                    label="Account"
                    options={xeroTenants.data.map((d) => ({ value: d.id, name: d.name }))}
                />
                <Button
                    onClick={() => xeroTenantsPost.post()}
                    mt-4
                    loading={xeroTenantsPost.loading}
                >
                    Submit
                </Button>
            </div>
        </>
    );
}

export default Tenant;
