import { services } from '../rest-client';

export function useXeroLink() {
    return services.merchant.useGet<{ redirectUrl: string }>('xero/link');
}

export function useXeroLinkPost(token: string) {
    return services.merchant.usePost(`xero/link?token=${token}`);
}

type Tenant = {
    id: string;
    name: string;
};

export function useXeroTenants() {
    return services.merchant.useGet<Tenant[]>(`xero/tenants`);
}

export function useXeroTenantsPost(tenantId: string) {
    return services.merchant.usePost<null, Tenant[]>(`xero/tenants?tenantId=${tenantId}`);
}

type Bank = {
    id: string;
    code: string;
    name: string;
    description: string;
    accountNumber: string;
    type: string;
    currencyCode: string;
    createdAt: string;
};
export function useXeroExpenseAccounts() {
    return services.merchant.useGet<Bank[]>(`xero/accounts/expense`, { lazy: true });
}

export function useXeroBankAccounts() {
    return services.merchant.useGet<Bank[]>(`xero/accounts/bank`, { lazy: true });
}

export function useXeroAccountsConfigure(bankAccountId: string, feeAccountId: string) {
    return services.merchant.usePost(
        `xero/accounts/configure?bankAccountId=${bankAccountId}&feeAccountId=${feeAccountId}`
    );
}

export function useXeroSync() {
    return services.merchant.usePost(`xero/sync`);
}

export function useXeroInvoices() {
    return services.merchant.useGet(`xero/invoices`);
}
