import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { addMilliseconds, fromUnixTime, isBefore } from 'date-fns';
import { AxiosInstance } from 'axios';
import { normalizePath, wait } from '../../utils';
import { RootState, StoreData } from '../store/reducer';

type Options = {
    interval?: number;
    lazy?: boolean;
};

export default function getGetGenerator(api: AxiosInstance, name: string) {
    return function useGet<T>(u: string, options: Options = {}) {
        const url = normalizePath(u);
        const props = useSelector((state: RootState) =>
            R.path<StoreData>([name, url, 'get'], state)
        );
        const dispatch = useDispatch();
        const interval = useRef(false);
        const mounted = useRef(false);

        const reset = useCallback(() => {
            dispatch({ type: `${name}|${url}|get` });
        }, [dispatch, url]);

        const refetch = useCallback(async () => {
            try {
                dispatch({ type: `${name}|${url}|get|loading` });
                const response = await api.get(url);
                dispatch({ type: `${name}|${url}|get|data`, payload: response.data });
            } catch (error) {
                dispatch({ type: `${name}|${url}|get|error`, payload: error.toString() });
            }
        }, [dispatch, url]);

        useEffect(() => {
            if (!props && options.lazy) {
                reset();
            }
        }, [options.lazy, props, reset]);

        useEffect(() => {
            mounted.current = true;
            return () => {
                mounted.current = false;
            };
        }, []);

        const checkInterval = useCallback(async () => {
            if (options.interval && mounted.current) {
                if (
                    !props?.lastUpdated ||
                    isBefore(
                        addMilliseconds(
                            fromUnixTime(props.lastUpdated as number),
                            options.interval
                        ),
                        new Date()
                    )
                ) {
                    refetch();
                }
                await wait(options.interval);
                checkInterval();
            }
        }, [options.interval, props, refetch]);

        useEffect(() => {
            if (!options.lazy && (!props || (!props.data && !props.loading && !props.error))) {
                refetch();
            }
        }, [options.interval, options.lazy, props, refetch]);

        useEffect(() => {
            if (props && !interval.current) {
                interval.current = true;
                checkInterval();
            }
        }, [checkInterval, props]);

        const data = (props?.data as T) || undefined;

        return {
            data,
            loading: props?.loading,
            error: props?.error,
            executed: props?.executed,
            success: props?.success,
            refetch,
            reset,
        };
    };
}
