import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import qs from 'qs';
import logo from '../../assets/DuePay.png';
import Accounts from './Accounts';
import Link from './Link';
import Tenant from './Tenant';

function Xero(props: RouteComponentProps) {
    // eslint-disable-next-line react/destructuring-assignment
    const { code, step } = qs.parse(props.location?.search.slice(1) as string);

    const valid = code || step === 'tenant' || step === 'accounts';

    /**
     * Redirect if url not correct.
     */
    useEffect(() => {
        if (!valid && props.navigate) {
            props.navigate('/');
        }
    }, [props, valid]);

    if (!valid) {
        return null;
    }

    return (
        <div className="flex justify-center bg-background min-h-screen items-center relative">
            <img src={logo} alt="logo" className="w-48 absolute top-0 left-0 m-6" />
            <div className="max-w-md w-full bg-white shadow md:p-8 px-2 py-4 flex flex-col">
                {(() => {
                    if (code) {
                        return <Link {...props} code={code as string} />;
                    }
                    if (step === 'tenant') {
                        return <Tenant {...props} />;
                    }
                    if (step === 'accounts') {
                        return <Accounts {...props} />;
                    }
                    return null;
                })()}
            </div>
        </div>
    );
}

export default Xero;
