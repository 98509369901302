/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as Loading } from './loading.svg';

export default function Button({
    children,
    onClick,
    type,
    disabled,
    loading,
    small,
    ...props
}: {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit' | undefined;
    disabled?: boolean;
    small?: boolean;
    loading?: boolean;
    [props: string]: unknown;
}) {
    // const type = _type || 'default';

    const className = clsx(
        props,
        'focus:outline-none transition duration-100 bg-blue-400 px-6 rounded text-white font-medium',
        {
            'hover:bg-opacity-75': !loading,
            'cursor-default': loading,
            'h-10': small,
            'h-12': !small,
        }
    );

    return (
        <button type={type} onClick={onClick} className={className} disabled={disabled}>
            {loading ? <Loading className="mx-auto" /> : children}
        </button>
    );
}
