import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from '@reach/router';
import Input from '../components/Input';
import Button from '../components/Button';
import logo from '../assets/DuePay.png';
import { useProfileRegister } from '../services/profile';
import { useLogin } from '../services/auth';

type FormData = {
    companyName: string;
    firstName: string;
    initial: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
};

function Register(props: RouteComponentProps) {
    const { handleSubmit, register, errors, watch, getValues } = useForm<FormData>();

    const profileRegister = useProfileRegister();
    const login = useLogin();

    function onSubmit(values: FormData) {
        profileRegister.post(values);
    }

    /**
     * After register, log in.  (Although register returns a token, the auth object is not the
     * same as log in.  Log in after register automatically to get a consistent auth object.
     */
    useEffect(() => {
        if (profileRegister.data && !login.data && props.navigate) {
            const values = getValues();
            login.post({
                username: values.email,
                password: values.password,
            });
        }
    }, [getValues, login, profileRegister.data, props]);

    /**
     * After log in > Go to home.  (Since user has not linked with Xero yet, it will auto redirect
     * to correct location)
     */
    useEffect(() => {
        if (login.data?.success && props.navigate) {
            props.navigate('/');
        }
    }, [login.data, props]);

    return (
        <div className="flex justify-center bg-background min-h-screen items-center relative">
            <img src={logo} alt="logo" className="w-48 absolute top-0 left-0 m-6" />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-w-md w-full bg-white shadow md:p-8 px-2 py-4 flex flex-col"
            >
                <h1 className="text-2xl font-bold">Your details</h1>
                <Input
                    label="Business name"
                    mt-4
                    inputRef={register({ required: true, minLength: 3 })}
                    name="companyName"
                    error={errors.companyName && 'Must be at least 3 characters long'}
                />
                <div className="flex mt-3 space-x-2">
                    <Input
                        label="First name"
                        flex-1
                        inputRef={register({ required: true, minLength: 2 })}
                        name="firstName"
                        error={errors.firstName && 'Must be at least 2 characters long'}
                    />
                    <Input
                        label="Initials"
                        w-16
                        inputRef={register({ required: true, minLength: 1 })}
                        name="initial"
                        error={errors.initial && 'Must be at least 1 character long'}
                    />
                    <Input
                        label="Last name"
                        flex-1
                        inputRef={register({ required: true, minLength: 2 })}
                        name="lastName"
                        error={errors.lastName && 'Must be at least 2 characters long'}
                    />
                </div>
                <Input
                    label="Email"
                    type="email"
                    mt-3
                    inputRef={register({ required: true, minLength: 4 })}
                    name="email"
                    error={errors.email && 'Must be at least 4 characters long'}
                />
                <div className="flex space-x-2 mt-3">
                    <Input
                        label="Password"
                        flex-1
                        type="password"
                        inputRef={register({ required: true, minLength: 6 })}
                        error={errors.password && 'Must be at least 6 characters long'}
                        name="password"
                    />
                    <Input
                        label="Repeat password"
                        flex-1
                        inputRef={register({
                            required: true,
                            validate: (val) => watch('password') === val,
                        })}
                        name="confirmPassword"
                        error={errors.confirmPassword && 'Must match password'}
                        type="password"
                    />
                </div>
                <Button
                    type="submit"
                    mx-auto
                    mt-8
                    w-40
                    loading={profileRegister.loading || login.loading}
                >
                    Create account
                </Button>
            </form>
        </div>
    );
}

export default Register;
