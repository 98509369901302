import { services } from '../rest-client';

type RegisterRequest = {
    companyName: string;
    firstName: string;
    initial: string;
    lastName: string;
    email: string;
    password: string;
};
type RegisterResponse = {
    accessToken: {
        token: string;
        tokenExpiry: string;
    };
    userId: string;
};

export function useProfileRegister() {
    return services.merchant.usePost<RegisterRequest, RegisterResponse>('/profile/register', {
        parseRequest: (request) => ({
            ...request,
            countryCode: undefined,
            recaptchaCode: undefined,
        }),
    });
}

type ProfileResponse = {
    user: {
        id: string;
        firstName: string;
        lastName: string;
        msisdn: string;
        msisdnConfirmed: boolean;
        email: string;
        emailConfirmed: boolean;
        nationalID: string;
        passport: string;
        customIdentifier: string;
        externalIdentifier: string;
        countryCode: string;
        languageCode: string;
        statusCode: 'PENDING';
        domain: string;
        source: string;
        active: boolean;
        createdAt: string;
    };
    account: {
        id: string;
        name: string;
        shortName: string;
        registrationNumber: string;
        vatNumber: string;
        countryCode: string;
        active: boolean;
        createdAt: string;
    };
    companies: {
        id: string;
        name: string;
        shortName: string;
        registrationNumber: string;
        vatNumber: string;
        countryCode: string;
        active: boolean;
        createdAt: string;
    }[];
    integrations: {
        id: string;
        merchantId: string;
        billProviderCode: string;
        settings: {
            key: string;
            value: string;
        }[];
        active: boolean;
        createdAt: string;
    }[];
};

export function useProfile() {
    return services.merchant.useGet<ProfileResponse>('/profile');
}
