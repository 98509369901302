import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Provider } from 'react-redux';
import createApi from './helpers/api';
import setupStore from './store';
import { RootState } from './store/reducer';

type Api = {
    baseURL: string;
    setAuthToken?: (state: RootState) => string | undefined;
};

type Options<T> = {
    localStorageKey: string;
    apis: {
        [name in keyof T]: Api;
    };
};

type ApiType = ReturnType<typeof createApi>;

type Client<T> = {
    services: Record<keyof T, ApiType>;
    logout: () => void;
    Provider: (props: { children: React.ReactNode }) => JSX.Element;
};

export function createClient<T>(options: Options<T>): Client<T> {
    const store = setupStore(options.localStorageKey);

    const services = R.reduce<
        keyof T,
        Partial<
            {
                [name in keyof T]: ApiType;
            }
        >
    >(
        (acc, key) => {
            const apiOptions = options.apis[key];
            const api = createApi(apiOptions.baseURL, key as string);
            acc[key] = api;
            return acc;
        },
        {},
        R.keys(options.apis)
    ) as { [name in keyof T]: ApiType };

    const InnerWrapper = ({ children }: { children: React.ReactNode }) => {
        const [setup, setSetup] = useState(false);

        useEffect(() => {
            R.forEachObjIndexed((service, name) => {
                const { setAuthToken } = options.apis[name];
                if (setAuthToken) {
                    service.api.interceptors.request.use((req) => {
                        const token = setAuthToken(store.getState());
                        if (token) {
                            req.headers.authorization = `Bearer ${token}`;
                        }
                        return req;
                    });
                }
            }, services);
            setSetup(true);
        }, []);

        if (!setup) return null;

        return <>{children}</>;
    };

    const OurProvider = ({ children }: { children: React.ReactNode }) => {
        return (
            <Provider store={store}>
                <InnerWrapper>{children}</InnerWrapper>
            </Provider>
        );
    };

    return {
        services,
        logout() {
            localStorage.removeItem(options.localStorageKey);
            store.dispatch({ type: 'LOGOUT' });
        },
        Provider: OurProvider,
    };
}
